import React, { Component } from 'react'
import PropTypes from 'prop-types'
import BackLink from 'components/BackLink'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  InputText,
  InputName,
  InputCPF,
  InputTel,
  InputLocked,
  InputCNPJ,
  Checkbox,
  Button,
  ButtonGroup,
} from '@yapay/design-system'
import {
  changeUser,
  stepActive,
  validateCPF,
  validatePhone,
  validateCNPJ,
  resetCNPJ,
} from 'actions'

export class SignUp extends Component {
  state = {
    isCNPJ: false,
    showErrorMessage: false,
    textBtn: 'Continuar',
  }

  checkCPF = event => {
    const { dispatch, seller_token } = this.props
    dispatch(validateCPF({ cpf: event.target.value, seller_token }))
  }

  checkPhone = event => {
    const { dispatch, seller_token } = this.props
    dispatch(validatePhone({ phone: event.target.value, seller_token }))
  }

  checkCNPJ = event => {
    const { dispatch, seller_token } = this.props
    dispatch(validateCNPJ({ cnpj: event.target.value, seller_token }))
  }

  submitForm = event => {
    event.preventDefault()
    const {
      email,
      name,
      cnpjValid,
      cpfValid,
      phoneValid,
      checkingPhone,
      checkingCNPJ,
      checkingCPF,
      history,
    } = this.props

    if (
      cpfValid &&
      phoneValid &&
      !checkingPhone &&
      !checkingCPF &&
      !checkingCNPJ &&
      email !== '' &&
      name !== ''
    ) {
      if (this.state.isCNPJ) {
        return cnpjValid ? history.push('/cep') : null
      } else {
        return history.push('/cep')
      }
    }

    return null
  }

  renderCNPJ = () => {
    if (!this.state.isCNPJ) return null

    return (
      <React.Fragment>
        <div className="relative">
          <InputCNPJ
            label="CNPJ"
            name="cnpj"
            id="cnpj"
            value={this.props.cnpj}
            valid={this.props.cnpjValid}
            errorMessage={this.props.cnpjError}
            onChange={this.changeField}
            onBlur={this.checkCNPJ}
            required={this.state.isCNPJ}
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35"
            height="35"
            viewBox="0 0 50 50"
            fill="#e1e1e1"
            className={`absolute top-0 ${
              this.props.checkingCNPJ ? '' : 'hidden'
            }`}
            style={{ top: '13px', right: '1rem' }}
          >
            <path d="M43.94 25.14a18.68 18.68 0 1 0-37.37 0h4.07a14.62 14.62 0 0 1 29.23 0h4.07z">
              <animateTransform
                attributeType="xml"
                attributeName="transform"
                type="rotate"
                from="0 25 25"
                to="360 25 25"
                dur="0.4s"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
        <InputText
          label="Razão Social"
          name="company_name"
          id="company_name"
          value={this.props.company_name}
          onChange={this.changeField}
          required={this.state.isCNPJ}
        />
      </React.Fragment>
    )
  }

  changeField = event => {
    const { target } = event
    this.props.dispatch(changeUser({ [target.name]: target.value }))
  }

  enableCNPJ = event => {
    const { dispatch } = this.props
    const isCNPJ = event.target.checked

    dispatch(resetCNPJ())
    this.setState({ isCNPJ })
  }

  componentDidMount() {
    const { cpf, seller_token, dispatch } = this.props

    dispatch(stepActive(1))
    dispatch(resetCNPJ())

    if (cpf) {
      dispatch(validateCPF({ cpf, seller_token }))
    }
  }

  render() {
    const {
      email,
      name,
      cpf,
      cpfValid,
      phoneValid,
      phone,
      cpfError,
    } = this.props

    return (
      <React.Fragment>
        <p>
          Para realizar seu pagamento com segurança utilizando o intermediador{' '}
          <span>Vindi</span>, preencha os campos abaixo:
        </p>

        <form onSubmit={this.submitForm}>
          <InputLocked
            label="E-mail"
            component={
              <BackLink className="input-locked__button hover:text-gray-900">
                Alterar
              </BackLink>
            }
            value={email}
            name="email"
            id="email"
          />
          <InputName
            label="Nome"
            name="name"
            id="name"
            value={name}
            onChange={this.changeField}
            required
          />
          <div className="relative">
            <InputCPF
              label="CPF"
              name="cpf"
              id="cpf"
              value={cpf}
              valid={cpfValid}
              onChange={this.changeField}
              onBlur={this.checkCPF}
              errorMessage={cpfError}
              required
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="35"
              viewBox="0 0 50 50"
              fill="#e1e1e1"
              className={`absolute top-0 ${
                this.props.checkingCPF ? '' : 'hidden'
              }`}
              style={{ top: '13px', right: '1rem' }}
            >
              <path d="M43.94 25.14a18.68 18.68 0 1 0-37.37 0h4.07a14.62 14.62 0 0 1 29.23 0h4.07z">
                <animateTransform
                  attributeType="xml"
                  attributeName="transform"
                  type="rotate"
                  from="0 25 25"
                  to="360 25 25"
                  dur="0.4s"
                  repeatCount="indefinite"
                />
              </path>
            </svg>
          </div>
          <div className="relative">
            <InputTel
              label="Telefone"
              name="phone"
              id="phone"
              value={phone}
              valid={phoneValid}
              onChange={this.changeField}
              onBlur={this.checkPhone}
              required
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="35"
              viewBox="0 0 50 50"
              fill="#e1e1e1"
              className={`absolute top-0 ${
                this.props.checkingPhone ? '' : 'hidden'
              }`}
              style={{ top: '13px', right: '1rem' }}
            >
              <path d="M43.94 25.14a18.68 18.68 0 1 0-37.37 0h4.07a14.62 14.62 0 0 1 29.23 0h4.07z">
                <animateTransform
                  attributeType="xml"
                  attributeName="transform"
                  type="rotate"
                  from="0 25 25"
                  to="360 25 25"
                  dur="0.4s"
                  repeatCount="indefinite"
                />
              </path>
            </svg>
          </div>

          <Checkbox
            className="mb-4"
            label="Cadastro empresarial"
            name="is_cnpj"
            onChange={this.enableCNPJ}
          />

          {this.renderCNPJ()}

          <ButtonGroup className="my-4 mt-8 mx-auto">
            <Button
              color="info"
              type="submit"
              className="mb-4"
              data-cy="continue-identification"
            >
              Continuar
            </Button>
            <BackLink className="button">Voltar</BackLink>
          </ButtonGroup>
        </form>
      </React.Fragment>
    )
  }
}

SignUp.propTypes = {
  user: PropTypes.object,
  transaction: PropTypes.object,
  dispatch: PropTypes.func,
  history: PropTypes.object,
}

const mapStateToProps = /* istanbul ignore next */ props => {
  let {
    user: {
      email,
      name,
      cpf,
      cpfValid,
      cpfRegistered,
      checkingCPF,
      masked_email,
      cnpj,
      cnpjValid,
      cnpjRegistered,
      checkingCNPJ,
      company_name,
      phone,
      phoneValid,
      checkingPhone,
      zip_code,
      city,
      state,
      neighborhood,
      street,
      number,
    },
    transaction: {
      seller: { token: seller_token },
      order: { token },
    },
  } = props

  const cpfError = cpfRegistered ? (
    !masked_email ? (
      'CPF já vinculado a uma conta Vindi, utilize outro CPF ou utilize o e-mail cadastrado'
    ) : (
      <>
        CPF vinculado ao e-mail {masked_email}, clique{' '}
        {<BackLink className="link">aqui</BackLink>} para se identificar
      </>
    )
  ) : (
    'CPF inválido'
  )

  const cnpjError = cnpjRegistered
    ? 'CNPJ já vinculado a uma conta Vindi, utilize outro CNPJ ou utilize o e-mail cadastrado'
    : 'CNPJ inválido'

  return {
    email,
    name,
    cpf,
    cpfValid,
    cpfRegistered,
    cpfError,
    checkingCPF,
    masked_email,
    cnpj,
    cnpjValid,
    cnpjError,
    checkingCNPJ,
    company_name,
    phone,
    zip_code,
    city,
    state,
    neighborhood,
    street,
    number,
    seller_token,
    token,
    phoneValid,
    checkingPhone,
  }
}

export default withRouter(connect(mapStateToProps)(SignUp))
