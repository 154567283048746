import React from 'react'

const useDecrementCounter = init => {
  const [timer, setTimer] = React.useState(null)
  const [counter, setCounter] = React.useState(init)

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCounter(counter => counter - 1)
    }, 1000)

    setTimer(interval)
  }, [])

  React.useEffect(() => () => clearInterval(timer), [])

  return [counter, () => clearInterval(timer), setCounter]
}

export default useDecrementCounter
