import React from 'react'
import PropTypes from 'prop-types'
import { Text } from '@yapay/design-system'
import useDecrementCounter from 'hooks/useDecrementCounter'

const RedirectWithTimer = ({ to, start = 10, reference, ...props }) => {
  const [counter, stopCounter, setCounter] = useDecrementCounter(start)

  React.useEffect(() => {
    if (counter === 0) {
      stopCounter()
      window.location.assign(to)
    }
  }, [counter])

  React.useEffect(() => () => stopCounter(), [])

  const renderSeconds = () => {
    return (
      <span className="text-blue-600">
        {counter} segundo{counter > 1 ? 's' : ''}!
      </span>
    )
  }

  if (reference) {
    reference.current = { setCounter }
  }

  if (counter === 0) {
    return <Text {...props}>Redirecionando...</Text>
  }

  return (
    <Text {...props}>
      Você será redirecionado para <span className="text-blue-600">{to}</span>{' '}
      em {renderSeconds()}
    </Text>
  )
}

RedirectWithTimer.propTypes = {
  to: PropTypes.string,
  start: PropTypes.number,
}

export default RedirectWithTimer
