import React from 'react'
import PropTypes from 'prop-types'
import MediaQuery from 'react-responsive'
import OrderDetail from 'components/OrderDetail'
import { withRouter, Link } from 'react-router-dom'
import { Button, ButtonGroup, Icon, Well } from '@yapay/design-system'
import Recaptcha from 'components/Recaptcha'
import RedirectWithTimer from './RedirectWithTimer'

export const TefOption = props => {
  const {
    pay,
    icon,
    text,
    btnText,
    showMessageError,
    transferResetErrorMessage,
    redirectUrl,
  } = props
  const [tokenRecaptcha, setTokenRecaptcha] = React.useState('')
  const [dirtyPayButton, setDirtyPayButton] = React.useState(false)

  React.useEffect(() => {
    transferResetErrorMessage()
  }, [])

  return (
    <div>
      <h2>Detalhes do pagamento</h2>

      <div className="flex items-center">
        <div>
          <h3 className="text-gray-600 flex items-center">
            <Icon
              icon={icon}
              width="35"
              height="22"
              className="fill-current text-gray-600 mr-4"
            />
            Transferência online {text}
          </h3>

          <p className="flex items-center">
            <strong className="pr-4 text-5xl">!</strong>
            <span>
              O pagamento será realizado no ambiente do banco selecionado.
            </span>
          </p>
        </div>
      </div>

      <MediaQuery query="(max-width: 767px)">
        <Well className="p-4 md:p-12 -mx-4" plain>
          <OrderDetail />
        </Well>
      </MediaQuery>

      <div className="mb-4">
        <Recaptcha
          onChange={tokenRecaptcha => setTokenRecaptcha(tokenRecaptcha)}
          invalid={!tokenRecaptcha && dirtyPayButton}
          errorMessage="Confirme o reCAPTCHA para continuar"
        />
      </div>

      {showMessageError && (
        <p className="text-red-400 text-center">
          Não foi possível gerar a transferência
        </p>
      )}

      {showMessageError && redirectUrl && (
        <RedirectWithTimer className="text-center -mt-4" to={redirectUrl} />
      )}

      <ButtonGroup responsive className="items-center">
        <Link to="/escolha-pagamento" className="button text-center">
          Voltar
        </Link>
        <Button
          className="px-0 sm:w-64"
          color="info"
          id="button-pay"
          data-cy="button-pay"
          onClick={() => {
            setDirtyPayButton(true)
            pay(tokenRecaptcha)
          }}
        >
          {btnText}
        </Button>
      </ButtonGroup>
    </div>
  )
}

TefOption.propTypes = {
  pay: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  btnText: PropTypes.string.isRequired,
  showMessageError: PropTypes.bool.isRequired,
}

export default withRouter(TefOption)
