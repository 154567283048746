import {
  LOGOUT,
  VALIDATING_CPF,
  VALIDATING_CEP,
  VALIDATING_PHONE,
  CPF_VALID,
  CPF_INVALID,
  CPF_REGISTERED,
  CNPJ_VALID,
  CNPJ_INVALID,
  CNPJ_REGISTERED,
  CEP_VALID,
  CEP_INVALID,
  CEP_EMPTY,
  PHONE_VALID,
  PHONE_INVALID,
  SIGNING_UP,
  SIGNUP_ERROR,
  SIGNUP_SUCCESS,
  REQUEST_IDENTIFICATION,
  IDENTIFICATION_ERROR,
  USER_REGISTERED,
  USER_UNREGISTERED,
  CHECKING_LOGIN,
  UPDATE_ADDRESS,
  UPDATING_ADDRESS,
  ADDRESS_UPDATED,
  ADDRESS_UPDATE_ERROR,
  LOGGED_IN,
  LOGIN_ERROR,
  CHANGE_LOGIN,
  REMOVE_LOGIN_BALANCE,
  CHANGE_USER,
  REQUESTS_EXCEEDED,
  VALIDATING_CNPJ,
  RESET_CNPJ,
} from 'actions'

const textSigningUp = 'Cadastrando...'
const textUpdating = 'Atualizando...'
const textLoading = 'Carregando...'
const textDefault = 'Continuar'

export default (
  state = {
    checkingCPF: false,
    cpfValid: null,
    cpfRegistered: false,
    cepValid: null,
    cnpj: '',
    checkingCNPJ: false,
    cnpjValid: null,
    company_name: '',
    phoneValid: null,
    checkingPhone: false,
    text: textDefault,
    addressChanged: false,
    showErrorMessage: false,
    errorMessage: '',
    email: '',
    masked_email: null,
    lockCep: false,
    zip_code: null,
    street: null,
    number: null,
    neighborhood: null,
    city: null,
    state: null,
    complement: null,
    continueWithPost: false,
    hideChangeButton: false,
    postAddress: false,
  },
  action,
) => {
  switch (action.type) {
    case LOGGED_IN: {
      return {
        ...state,
        ...action.data,
        registered: true,
        text: textDefault,
      }
    }

    case LOGIN_ERROR: {
      return {
        ...state,
        ...action.data,
        session_token: null,
        text: textDefault,
      }
    }

    case LOGOUT:
      return {
        ...state,
        cpfValid: false,
        cepValid: undefined,
        text: textDefault,
        postAddress: false,
        addressChanged: false,
        session_token: null,
      }

    // will ever update address after login
    case UPDATE_ADDRESS:
      return {
        ...state,
        ...action.data,
        cepValid: true,
        text: textDefault,
        postAddress: true,
        addressChanged: true,
      }

    case UPDATING_ADDRESS:
      return {
        ...state,
        text: textUpdating,
      }

    case ADDRESS_UPDATE_ERROR:
      return {
        ...state,
        text: textDefault,
        showErrorMessage: true,
        errorMessage: action.payload,
      }

    case ADDRESS_UPDATED:
      return {
        ...state,
        ...action.data,
        text: textDefault,
        addressChanged: false,
        showErrorMessage: false,
      }

    case CHECKING_LOGIN:
      return {
        ...state,
        checkingLogin: true,
        showErrorMessage: false,
        text: textLoading,
      }

    case IDENTIFICATION_ERROR:
      return {
        ...state,
        checkingLogin: false,
        showErrorMessage: true,
        errorMessage: action.payload,
        text: textDefault,
      }

    case REQUEST_IDENTIFICATION:
      return {
        ...state,
        email: action.email,
        checkingRegister: true,
        showErrorMessage: false,
        text: textLoading,
      }

    case USER_REGISTERED:
      return {
        ...state,
        ...action.data.buyer,
        ...action.data.address,
        registered: action.data.registered,
        checkingRegister: false,
        text: textDefault,
      }

    case USER_UNREGISTERED:
      return {
        ...state,
        ...action.data.buyer,
        registered: action.data.registered,
        checkingRegister: false,
        text: textDefault,
      }

    case CHANGE_USER:
      return {
        ...state,
        ...action.payload,
        addressChanged: true,
      }

    case VALIDATING_CPF:
      return {
        ...state,
        checkingCPF: true,
        cpfValid: null,
        masked_email: null,
      }

    case CPF_VALID:
      return {
        ...state,
        checkingCPF: false,
        cpfValid: true,
        masked_email: null,
      }

    case CPF_INVALID:
      return {
        ...state,
        cpfValid: false,
        checkingCPF: false,
        cpfRegistered: false,
        masked_email: null,
      }

    case CPF_REGISTERED:
      return {
        ...state,
        cpfRegistered: true,
        checkingCPF: false,
        cpfValid: false,
        masked_email: action.masked_email,
      }

    case VALIDATING_CNPJ:
      return {
        ...state,
        checkingCNPJ: true,
        cnpjValid: null,
      }

    case RESET_CNPJ:
      return {
        ...state,
        cnpj: '',
        company_name: '',
        checkingCNPJ: false,
        cnpjValid: null,
        cnpjRegistered: false,
      }

    case CNPJ_VALID:
      return {
        ...state,
        checkingCNPJ: false,
        cnpjValid: true,
      }

    case CNPJ_INVALID:
      return {
        ...state,
        checkingCNPJ: false,
        cnpjValid: false,
        cnpjRegistered: false,
      }

    case CNPJ_REGISTERED:
      return {
        ...state,
        checkingCNPJ: false,
        cnpjValid: false,
        cnpjRegistered: true,
      }

    case VALIDATING_PHONE:
      return {
        ...state,
        phoneValid: true,
        checkingPhone: true,
      }

    case PHONE_VALID:
      return {
        ...state,
        phoneValid: true,
        checkingPhone: false,
      }

    case PHONE_INVALID:
      return {
        ...state,
        phoneValid: false,
        checkingPhone: false,
      }

    case VALIDATING_CEP:
      return {
        ...state,
        cepValid: undefined,
        checkingCep: true,
        zip_code: action.cep,
      }

    case CEP_VALID:
      return {
        ...state,
        city: '',
        state: '',
        neighborhood: '',
        street: '',
        number: '',
        complement: '',
        ...action.data,
        checkingCep: false,
        cepValid: true,
        text: textDefault,
      }

    case CEP_INVALID:
      return {
        ...state,
        isFetching: false,
        cepValid: false,
        checkingCep: false,
        zip_code: action.cep,
        text: textDefault,
      }

    case CEP_EMPTY:
      return {
        ...state,
        isFetching: false,
        cepValid: undefined,
        checkingCep: false,
      }

    case SIGNING_UP:
      return {
        ...state,
        text: textSigningUp,
      }

    case SIGNUP_ERROR:
      return {
        ...state,
        text: textDefault,
        showErrorMessage: true,
        errorMessage: action.payload,
      }

    case SIGNUP_SUCCESS:
      return {
        ...state,
        text: textDefault,
        addressChanged: false,
      }

    case REQUESTS_EXCEEDED:
      return {
        ...state,
        requestsExceeded: true,
      }

    case CHANGE_LOGIN:
      return {
        ...state,
        postAddress: false,
        email: '',
        name: '',
        phone: '',
        cpf: '',
        zip_code: null,
        street: null,
        number: null,
        neighborhood: null,
        city: null,
        state: null,
        complement: null,
        requestsExceeded: false,
        text: textDefault,
        cpfValid: null,
        phoneValid: null,
      }

    case REMOVE_LOGIN_BALANCE:
      return {
        ...state,
        session_token: null,
      }

    default:
      return state
  }
}
