import axios from 'axios'
axios.defaults.baseURL = process.env.REACT_APP_API
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.timeout = 15000

/**
 * Step Active
 */
export const STEP_ACTIVE = 'STEP_ACTIVE'

export const stepActive = step => ({
  type: STEP_ACTIVE,
  step,
})

/**
 * Requests Exceeded
 * Session Expired
 * General errors
 */
export const REQUESTS_EXCEEDED = 'REQUESTS_EXCEEDED'
export const SESSION_EXPIRED = 'SESSION_EXPIRED'
export const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE'

export const userBlocked = () => ({
  type: REQUESTS_EXCEEDED,
})

export const showErrorMessage = bool => ({
  type: SHOW_ERROR_MESSAGE,
  hide: bool,
})

/**
 * Change login
 */
export const CHANGE_LOGIN = 'CHANGE_LOGIN'

export const changeLogin = () => ({
  type: CHANGE_LOGIN,
})

/**
 * Remove login from balance payment
 */
export const REMOVE_LOGIN_BALANCE = 'REMOVE_LOGIN_BALANCE'

export const removeLoginBalance = () => ({
  type: REMOVE_LOGIN_BALANCE,
})

/**
 * Set Order
 * Get the order data and update the state
 * If order token invalid or api return some error
 * the order will be set Undefined
 */
export const ORDER_UNDEFINED = 'ORDER_UNDEFINED'
export const SET_ORDER = 'SET_ORDER'

export const setOrder = ({ token, type }) => ({
  type: SET_ORDER,
  payload: {
    token,
    type,
  },
})

export const orderUndefined = () => ({
  type: ORDER_UNDEFINED,
})

/**
 * Open/Close Cart
 * This can be seem in mobile version
 */
export const OPEN_CART = 'OPEN_CART'
export const CLOSE_CART = 'CLOSE_CART'

export const openCart = () => ({
  type: OPEN_CART,
})

export const closeCart = () => ({
  type: CLOSE_CART,
})

/**
 * Answer authentication
 */
export const VALIDATE_ANSWER = 'VALIDATE_ANSWER'
export const ANSWER_CORRECT = 'ANSWER_CORRECT'
export const ANSWER_WRONG = 'ANSWER_WRONG'

export const requestQuestionValidation = () => ({
  type: VALIDATE_ANSWER,
})

export const answerCorrect = json => ({
  type: ANSWER_CORRECT,
  data: json,
})

export const answerWrong = json => ({
  type: ANSWER_WRONG,
  data: json,
})

/**
 * Login
 * Generic login with custom data
 * Can be used after Sign Up or Sign In
 */
export const CHECKING_LOGIN = 'CHECKING_LOGIN'
export const CHECKING_LOGOUT = 'CHECKING_LOGOUT'
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS'
export const LOGGED_IN = 'LOGGED_IN'
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const LOGOUT_ERROR = 'LOGOUT_ERROR'
export const LOGIN_ERROR = 'LOGIN_ERROR'

export const checkingLogout = () => ({
  type: CHECKING_LOGOUT,
})

export const logout = () => ({
  type: LOGOUT,
})

export const logoutError = error => ({
  type: LOGOUT_ERROR,
  error,
})

export const checkingLogin = () => ({
  type: CHECKING_LOGIN,
})

export const updateUserContact = data => ({
  type: UPDATE_ADDRESS,
  data,
})

export const userLoggedIn = data => ({
  type: LOGGED_IN,
  data,
})

export const userLoginError = error => ({
  type: LOGIN_ERROR,
  error,
})

export const login = data => dispatch => {
  dispatch(checkingLogin())

  let order = JSON.parse(sessionStorage['state'])['transaction']['order']

  return axios
    .post('/login?token=' + order.token + '&type=' + order.type, data)
    .then(resp => {
      dispatch(updateUserContact(resp.data.contact))
      return dispatch(userLoggedIn(resp.data))
    })
    .catch(({ response }) => dispatch(userLoginError(response.data.error)))
}

export const loginUpdate = data => dispatch => {
  dispatch(checkingLogin())

  return axios
    .post('/login/update', data)
    .then(resp => {
      return dispatch(userLoggedIn(resp.data))
    })
    .catch(({ response }) => dispatch(userLoginError(response.data.error)))
}

export const signOut = session_token => dispatch => {
  dispatch(checkingLogout())

  return axios
    .post('/login/destroy', { session_token: session_token })
    .then(_resp => {
      return dispatch(logout())
    })
    .catch(({ response }) => dispatch(logoutError(response)))
}

export const loginAuth = data => dispatch => {
  dispatch(checkingLogin())

  return axios
    .post('/login?token=' + data.order.token + '&type=' + data.order.type, data)
    .then(resp => {
      return dispatch(userLoggedIn(resp.data))
    })
    .catch(({ response }) => dispatch(userLoginError(response.data.error)))
}

/**
 * Get Temporary Order
 * Get Definitive Order
 */
export const REQUEST_INFO = 'REQUEST_INFO'
export const RECEIVE_INFO = 'RECEIVE_INFO'

export const requestInfo = token => ({
  type: REQUEST_INFO,
  token,
})

export const receiveInfo = json => ({
  type: RECEIVE_INFO,
  data: json,
})

export const getOrder = order => dispatch => {
  dispatch(requestInfo(order.token))
  return axios
    .get('/tmp_order', { params: { ...order } })
    .then(resp => dispatch(receiveInfo(resp.data)))
    .catch(() => dispatch(orderUndefined()))
}

export const getDefOrder = order => dispatch => {
  dispatch(requestInfo(order))
  return axios
    .get('/order', { params: { token: order } })
    .then(resp => dispatch(receiveInfo(resp.data)))
    .catch(() => dispatch(orderUndefined()))
}

/**
 * Generate Pix
 * Create a new transaction with link to pix
 */
export const PIX_DID_GENERATE = 'PIX_DID_GENERATE'
export const PIX_ERROR = 'PIX_ERROR'
export const GENERATING_PIX = 'GENERATING_PIX'

export const pixDidGenerate = json => ({
  type: PIX_DID_GENERATE,
  data: json.data,
})

export const pixError = data => ({
  type: PIX_ERROR,
  data,
})

export const generatingPix = () => ({
  type: GENERATING_PIX,
})

export const generatePix = params => dispatch => {
  dispatch(generatingPix())
  const data = JSON.stringify({ ...params })
  return axios
    .post('/pay', data)
    .then(resp => {
      return resp.data.status === 'failed'
        ? dispatch(pixError(resp.data))
        : dispatch(pixDidGenerate(resp))
    })
    .catch(err => dispatch(pixError(err)))
}

/**
 * Generate Billet
 * Create a new transaction with link to billet
 */
export const BILLET_DID_GENERATE = 'BILLET_DID_GENERATE'
export const BILLET_ERROR = 'BILLET_ERROR'
export const GENERATING_BILLET = 'GENERATING_BILLET'

export const billetDidGenerate = json => ({
  type: BILLET_DID_GENERATE,
  data: json.data,
})

export const billetError = data => ({
  type: BILLET_ERROR,
  data,
})

export const generatingBillet = () => ({
  type: GENERATING_BILLET,
})

export const generateBillet = params => dispatch => {
  dispatch(generatingBillet())
  const data = JSON.stringify({ ...params })
  return axios
    .post('/pay', data)
    .then(resp => {
      return resp.data.status === 'failed'
        ? dispatch(billetError(resp.data))
        : dispatch(billetDidGenerate(resp))
    })
    .catch(err => dispatch(billetError(err)))
}

/**
 * Generate Bolepix
 * Create a new transaction with link to bolepix
 */
export const BOLEPIX_DID_GENERATE = 'BOLEPIX_DID_GENERATE'
export const BOLEPIX_ERROR = 'BOLEPIX_ERROR'
export const GENERATING_BOLEPIX = 'GENERATING_BOLEPIX'

export const bolepixDidGenerate = json => ({
  type: BOLEPIX_DID_GENERATE,
  data: json.data,
})

export const bolepixError = data => ({
  type: BOLEPIX_ERROR,
  data,
})

export const generatingBolepix = () => ({
  type: GENERATING_BOLEPIX,
})

export const generateBolepix = params => dispatch => {
  dispatch(generatingBolepix())
  const data = JSON.stringify({ ...params })
  return axios
    .post('/pay', data)
    .then(resp => {
      return resp.data.status === 'failed'
        ? dispatch(bolepixError(resp.data))
        : dispatch(bolepixDidGenerate(resp))
    })
    .catch(err => dispatch(bolepixError(err)))
}

/**
 * Generate Transfer
 * Create a new transaction with link to the bank
 */
export const GENERATING_TRANSFER = 'GENERATING_TRANSFER'
export const TRANSFER_DID_GENERATE = 'TRANSFER_DID_GENERATE'
export const TRANSFER_ERROR = 'TRANSFER_ERROR'
export const TRANSFER_RESET_ERROR_MESSAGE = 'TRANSFER_RESET_ERROR_MESSAGE'

export const generatingTransfer = () => ({
  type: GENERATING_TRANSFER,
})

export const transferDidGenerate = json => ({
  type: TRANSFER_DID_GENERATE,
  data: json.data,
})

export const transferError = data => ({
  type: TRANSFER_ERROR,
  data,
})

export const transferResetErrorMessage = () => ({
  type: TRANSFER_RESET_ERROR_MESSAGE,
})

export const generateTransfer = params => dispatch => {
  dispatch(generatingTransfer())
  const data = JSON.stringify({ ...params })
  return axios
    .post('/pay', data)
    .then(resp => {
      return resp.data.status === 'failed'
        ? dispatch(transferError(resp.data))
        : dispatch(transferDidGenerate(resp))
    })
    .catch(err => dispatch(transferError(err)))
}

/**
 * Pay with card
 * @param status Can be ['processing', 'approved', 'denied']
 * if status returns null a generic error message will be show
 */
export const PAYING_CARD = 'PAYING_CARD'
export const CARD_PROCESSING = 'CARD_PROCESSING'
export const CARD_APPROVED = 'CARD_APPROVED'
export const CARD_DENIED = 'CARD_DENIED'

export const payingWithCard = () => ({
  type: PAYING_CARD,
})

export const cardProcessing = data => ({
  type: CARD_PROCESSING,
  data,
})

export const cardApproved = data => ({
  type: CARD_APPROVED,
  data,
})

export const cardDenied = data => ({
  type: CARD_DENIED,
  data,
})

export const payWithCard = params => dispatch => {
  dispatch(payingWithCard())
  const data = JSON.stringify({ ...params })

  return axios
    .post('/pay', data)
    .then(resp => {
      switch (resp.data.status) {
        case 'processing':
          return dispatch(cardProcessing(resp.data))

        case 'approved':
          return dispatch(cardApproved(resp.data))

        case 'denied':
          return dispatch(cardDenied(resp.data))

        default:
          return null
      }
    })
    .catch(() => null)
}

/**
 * Recover Password
 * Send the link to email to start the password recovery proccess
 */
export const SENDING_PASSWORD_RECOVERY = 'SENDING_PASSWORD_RECOVERY'
export const PASSWORD_RECOVERY_SENT = 'PASSWORD_RECOVERY_SENT'
export const PASSWORD_RECOVERY_ERROR = 'PASSWORD_RECOVERY_ERROR'

export const sendingPasswordRecovery = () => ({
  type: SENDING_PASSWORD_RECOVERY,
})

export const passwordRecoverySent = () => ({
  type: PASSWORD_RECOVERY_SENT,
})

export const passwordRecoveryError = () => ({
  type: PASSWORD_RECOVERY_ERROR,
})

export const recoverPassword = email => dispatch => {
  dispatch(sendingPasswordRecovery(email))
  const data = JSON.stringify({ email })
  return axios
    .post('/password_recovery', data)
    .then(() => dispatch(passwordRecoverySent()))
    .catch(err => {
      err.response.status === 429
        ? dispatch(userBlocked())
        : dispatch(passwordRecoveryError())
    })
}

/**
 * Get Customer
 * Get customer information from API
 * Check if user is registered or not
 */
export const REQUEST_IDENTIFICATION = 'REQUEST_IDENTIFICATION'
export const IDENTIFICATION_ERROR = 'IDENTIFICATION_ERROR'
export const USER_REGISTERED = 'USER_REGISTERED'
export const USER_UNREGISTERED = 'USER_UNREGISTERED'
const errorMessage =
  'Não foi possível realizar a identificação, tente novamente mais tarde.'

export const requestIdentification = email => ({
  type: REQUEST_IDENTIFICATION,
  email,
})

export const identificationError = ({ error = errorMessage }) => ({
  type: IDENTIFICATION_ERROR,
  payload: error,
})

export const userRegistered = data => ({
  type: USER_REGISTERED,
  data,
})

export const userUnregistered = data => ({
  type: USER_UNREGISTERED,
  data,
})

export const getCustomer = data => dispatch => {
  dispatch(requestIdentification(data.email))
  return axios
    .get('/customer?token=' + data.token, { params: { ...data } })
    .then(resp => {
      if (data.postAddress) resp.data.address = null
      return resp.data.registered
        ? dispatch(userRegistered(resp.data))
        : dispatch(userUnregistered(resp.data))
    })
    .catch(({ response }) => dispatch(identificationError(response.data)))
}

/**
 * Validate CPF
 * In Sign Up page when user blurs the CPF field
 * this action is dispatched and the form is blocked
 * to submit until the CPF become valid
 */
export const VALIDATING_CPF = 'VALIDATING_CPF'
export const CPF_VALID = 'CPF_VALID'
export const CPF_REGISTERED = 'CPF_REGISTERED'
export const CPF_INVALID = 'CPF_INVALID'

export const validatingCPF = cpf => ({
  type: VALIDATING_CPF,
  cpf,
})

export const cpfRegistered = (cpf, masked_email) => ({
  type: CPF_REGISTERED,
  cpf,
  masked_email,
})

export const cpfValid = cpf => ({
  type: CPF_VALID,
  cpf,
})

export const cpfInvalid = cpf => ({
  type: CPF_INVALID,
  cpf,
})

export const validateCPF = ({ cpf, seller_token }) => dispatch => {
  dispatch(validatingCPF(cpf))
  const data = JSON.stringify({ cpf, seller_token })
  return axios.post('/customer', data).catch(error => {
    if (error.response.status === 429 || error.response.status === 500) {
      return dispatch(cpfInvalid(cpf))
    }

    const arrayErrors = error.response.data.validation_errors

    if (arrayErrors.includes('cpf')) {
      dispatch(cpfInvalid(cpf))
    } else if (
      arrayErrors.includes('cpf_registered') &&
      process.env.REACT_APP_NEW_PAYMENT_FLOW_TOGGLE !== 'true'
    ) {
      const transaction = JSON.parse(sessionStorage['state'])['transaction']
      const params = {
        token: transaction['order']['token'],
        type: transaction['type'],
        cpf: cpf.replace(/[. ,:-]+/g, ''),
      }
      return axios
        .get('/customer', { params })
        .then(resp => {
          dispatch(cpfRegistered(cpf, resp.data.masked_email))
        })
        .catch(() => dispatch(cpfRegistered(cpf, null)))
    } else {
      dispatch(cpfValid(cpf))
    }
  })
}

/**
 * Validate Phone
 * In Sign Up page when user blurs the phone field
 * this action is dispatched and the form is blocked
 * to submit until the phone become valid
 */
export const VALIDATING_PHONE = 'VALIDATING_PHONE'
export const PHONE_VALID = 'PHONE_VALID'
export const PHONE_INVALID = 'PHONE_INVALID'

export const validatingPhone = phone => ({
  type: VALIDATING_PHONE,
  phone,
})

export const phoneValid = () => ({
  type: PHONE_VALID,
})

export const phoneInvalid = () => ({
  type: PHONE_INVALID,
})

export const validatePhone = ({ phone, seller_token }) => dispatch => {
  dispatch(validatingPhone(phone))
  const data = JSON.stringify({ phone, seller_token })
  return axios.post('/customer', data).catch(error => {
    if (error.response.status === 429 || error.response.status === 500) {
      return dispatch(phoneInvalid(phone))
    }

    return error.response.data.validation_errors.includes('phone')
      ? dispatch(phoneInvalid(phone))
      : dispatch(phoneValid(phone))
  })
}

/**
 * Validate CNPJ
 * In Sign Up page when user blurs the cnpj field
 * this action is dispatched and the form is blocked
 * to submit until the cnpj become valid
 */
export const VALIDATING_CNPJ = 'VALIDATING_CNPJ'
export const RESET_CNPJ = 'CNPJ_RESET'
export const CNPJ_VALID = 'CNPJ_VALID'
export const CNPJ_REGISTERED = 'CNPJ_REGISTERED'
export const CNPJ_INVALID = 'CNPJ_INVALID'

export const validatingCNPJ = cnpj => ({
  type: VALIDATING_CNPJ,
  cnpj,
})

export const resetCNPJ = () => ({
  type: RESET_CNPJ,
})

export const cnpjValid = () => ({
  type: CNPJ_VALID,
})

export const cnpjInvalid = () => ({
  type: CNPJ_INVALID,
})

export const cnpjRegistered = () => ({
  type: CNPJ_REGISTERED,
})

export const validateCNPJ = ({ cnpj, seller_token }) => dispatch => {
  dispatch(validatingCNPJ(cnpj))
  const data = JSON.stringify({ cnpj, seller_token })
  return axios.post('/customer', data).catch(error => {
    if (error.response.status === 429 || error.response.status === 500) {
      return dispatch(cnpjInvalid(cnpj))
    }

    const arrayErrors = error.response.data.validation_errors

    if (arrayErrors.includes('cnpj')) {
      dispatch(cnpjInvalid(cnpj))
    } else if (
      arrayErrors.includes('cnpj_registered') &&
      process.env.REACT_APP_NEW_PAYMENT_FLOW_TOGGLE !== 'true'
    ) {
      dispatch(cnpjRegistered(cnpj))
    } else {
      dispatch(cnpjValid(cnpj))
    }
  })
}

/**
 * Get CEP
 * This action update the state with address information
 * After set the CEP it fill up the address fields
 */
export const VALIDATING_CEP = 'VALIDATING_CEP'
export const CEP_VALID = 'CEP_VALID'
export const CEP_INVALID = 'CEP_INVALID'
export const CEP_EMPTY = 'CEP_EMPTY'

export const validateCEP = cep => ({
  type: VALIDATING_CEP,
  cep,
})

export const cepValid = (data, zip_code) => ({
  type: CEP_VALID,
  data: { ...data, zip_code },
})

export const cepInvalid = cep => ({
  type: CEP_INVALID,
  cep,
})

export const cepEmpty = cep => ({
  type: CEP_EMPTY,
  cep,
})

export const getCEP = cep => dispatch => {
  dispatch(validateCEP(cep))
  return axios
    .get('/zip_code', { params: { zip_code: cep } })
    .then(resp => dispatch(cepValid(resp.data, cep)))
    .catch(() => dispatch(cepInvalid(cep)))
}

/**
 * Sign Up
 * Create a new User
 * If success it also Sign In the new user
 */
export const SIGNING_UP = 'SIGNING_UP'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_ERROR = 'SIGNUP_ERROR'

export const signingUp = () => ({
  type: SIGNING_UP,
})

export const signUpSuccess = data => ({
  type: SIGNUP_SUCCESS,
  data,
})

export const signUpError = () => ({
  type: SIGNUP_ERROR,
})

export const signUp = data => dispatch => {
  dispatch(signingUp())
  return axios
    .post('/customer', JSON.stringify(data))
    .then(resp => {
      dispatch(signUpSuccess())
      return dispatch(userLoggedIn(resp.data))
    })
    .catch(() => dispatch(signUpError()))
}

/**
 * New Address
 * Update the main address when user change some field
 * after login.
 * *This action create a new address, but not set it as main address
 */
export const UPDATING_ADDRESS = 'UPDATING_ADDRESS'
export const ADDRESS_UPDATE_ERROR = 'ADDRESS_UPDATE_ERROR'
export const ADDRESS_UPDATED = 'ADDRESS_UPDATED'

export const updatingAddress = () => ({
  type: UPDATING_ADDRESS,
})

export const addressUpdated = data => ({
  type: ADDRESS_UPDATED,
  data,
})

export const addressUpdateError = ({ error }) => ({
  type: ADDRESS_UPDATE_ERROR,
  payload: error,
})

export const newAddress = data => dispatch => {
  dispatch(updatingAddress())
  return axios
    .post('/address', JSON.stringify(data))
    .then(resp => dispatch(addressUpdated(resp.data)))
    .catch(({ response }) => dispatch(addressUpdateError(response.data)))
}

/**
 * Get Address
 * Get the address without mask of current logged user
 */
export const ADDRESS_REQUEST = 'ADDRESS_REQUEST'
export const ADDRESS_ERROR = 'ADDRESS_ERROR'

export const addressSucess = data => ({
  type: UPDATE_ADDRESS,
  data,
})

export const addressError = () => ({
  type: ADDRESS_ERROR,
})

export const addressRequest = session_token => dispatch => {
  return axios
    .get('/address', { params: { session_token } })
    .then(({ data }) => dispatch(addressSucess(data)))
    .catch(() => dispatch(addressError()))
}

/**
 * Verification Code
 * Send verification code when user forget the password
 */
export const SENDING_VERIFICATION_CODE = 'SENDING_VERIFICATION_CODE'
export const VERIFICATION_CODE_SENT = 'VERIFICATION_CODE_SENT'
export const VERIFICATION_CODE_ERROR = 'VERIFICATION_CODE_ERROR'

export const sendingVerificationCode = () => ({
  type: SENDING_VERIFICATION_CODE,
})

export const verificationCodeSent = () => ({
  type: VERIFICATION_CODE_SENT,
})

export const verificationCodeError = () => ({
  type: VERIFICATION_CODE_ERROR,
})

export const sendVerificationCode = email => dispatch => {
  dispatch(sendingVerificationCode())
  return axios
    .get('https://reqres.in/api/users?delay=3', JSON.stringify(email))
    .then(resp => dispatch(verificationCodeSent(resp.data)))
    .catch(err => dispatch(verificationCodeError(err)))
}

/**
 * Get Balance
 * Get the balance from logged users
 */
export const FETCH_BALANCE = 'FETCH_BALANCE'
export const FETCH_BALANCE_ERROR = 'FETCH_BALANCE_ERROR'
export const FETCH_BALANCE_SUCCESS = 'FETCH_BALANCE_SUCCESS'
export const RESET_BALANCE = 'RESET_BALANCE'

export const resetBalance = () => dispatch => {
  dispatch({ type: RESET_BALANCE })
}

export const getBalance = data => dispatch => {
  dispatch({ type: FETCH_BALANCE })

  return axios
    .get('/balance', { params: { ...data } })
    .then(resp => {
      return dispatch({
        type: FETCH_BALANCE_SUCCESS,
        payload: resp.data,
      })
    })
    .catch(error => {
      return dispatch({
        type: FETCH_BALANCE_ERROR,
        payload: error,
      })
    })
}

/**
 * Pay with balance
 * Function to send data to api
 */
export const PAY_BALANCE = 'PAY_BALANCE'
export const PAY_BALANCE_SUCCESS = 'PAY_BALANCE_SUCCESS'
export const PAY_BALANCE_ERROR = 'PAY_BALANCE_ERROR'

export const payWithBalance = params => dispatch => {
  dispatch({ type: PAY_BALANCE })
  const data = JSON.stringify(params)
  return axios
    .post('/pay', data)
    .then(resp => {
      return resp.data.status === 'failed'
        ? dispatch({ type: PAY_BALANCE_ERROR, data: resp.data })
        : dispatch({ type: PAY_BALANCE_SUCCESS, data: resp.data })
    })
    .catch(error => dispatch({ type: PAY_BALANCE_ERROR, payload: error }))
}

/**
 * Change User Fields
 */
export const CHANGE_USER = 'CHANGE_USER'
export const changeUser = field => ({
  type: CHANGE_USER,
  payload: field,
})

/**
 * Enable/Disable Link Logo
 */
export const TOGGLE_LINK_LOGO = 'TOGGLE_LINK_LOGO'
export const toggleLinkLogo = () => ({ type: TOGGLE_LINK_LOGO })
