import React from 'react'
import Auth from 'components/Auth'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga'
import { withRouter } from 'react-router-dom'
import { Button } from '@yapay/design-system'
import { connect } from 'react-redux'
import { logout } from 'actions'

export const AuthButton = ({ history, dispatch }) => {
  if (Auth.isAuthenticated) {
    return (
      <Button
        onClick={() => {
          const state = JSON.parse(sessionStorage.getItem('state'))
          const type = state.transaction.type
          let url = `/transacao/${state.transaction.order.token}`

          /* istanbul ignore else */
          if (typeof type !== 'undefined') url = url + `/${type}`

          ReactGA.event({
            category: 'User',
            action: 'Logout',
          })
          dispatch(logout())
          Auth.signout()
          history.push(url)
        }}
      >
        Voltar
      </Button>
    )
  }

  return null
}

AuthButton.propTypes = {
  history: PropTypes.object,
  dispatch: PropTypes.func,
}

export default withRouter(connect()(AuthButton))
